.identity {
	// margin-top: 5%;
	margin-bottom: 5%;
	h2 {
		color: $lakeview-blue;
	}
	p {
		line-height: 1.5em;
		font-size: 1.25em;
		color: $lakeview-dark-gray;
		text-align: left-justify;
	}
}