.media {
	.media-image {
		-moz-box-shadow:    0 0 15px 3px $media-border-shadow;
  		-webkit-box-shadow: 0 0 15px 3px $media-border-shadow;
  		box-shadow:         0 0 15px 3px $media-border-shadow;
	}
	.media-image:hover {
		-moz-box-shadow:    0 0 15px 3px $media-border-shadow-hover;
  		-webkit-box-shadow: 0 0 15px 3px $media-border-shadow-hover;
  		box-shadow:         0 0 15px 3px $media-border-shadow-hover;
	}
}
.current-series {
	@include make-sm-column(6)
	margin-bottom: 5%;
	h2 {
		margin-top: 0;
		font-size: 36px;
		font-weight: 700;
		a {
			color: $gray-light;
		}
	}
	h3 {
		color: $lakeview-blue;
		font-size: 20px;
	}
}

.recent-sermons {
	@include make-row();
}
.recent-sermon {
	@include make-sm-column(3);
	margin-bottom: 3%;
	h3 {
		margin-top: 5px;
		font-weight: 700;
		a {
			color: $gray-light;
		}
		a:hover {
			color: $lakeview-blue;
		}
	}
	h4 {
		color: $lakeview-blue;
	}
	h5 {
		color: $gray-light;
		font-weight: 700;
	}
}
.recent-sermon-lg {
	@include make-sm-column(4);
}
.media-page-title {
	font-size: 60px;
	font-weight: 100;
	color: $gray-light;
	text-align: center;
	@media all and (max-width: 699px) and (min-width: 300px) {
		font-size: 36px;
	}
}