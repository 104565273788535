.footer {
	width: 100%;
	// border-top: 1px dashed $section-border;
	background: $gray-darker;
	// margin-top: 4%;
	// margin-bottom: 3%;
	// border-bottom: 1px dashed $section-border;
	.footer-content {
		margin-top: 4%;
		margin-bottom: 4%;
		@include make-sm-column(4);
		@include make-xs-column(12);
		.social {
			height: 40px;
			// margin-top: 3%;
			.icon {
				@include make-sm-column(3);
				@include make-xs-column(4);
			}
		}
		@media all and (max-width: 699px) and (min-width: 300px) {
			margin-top: 6%;
			margin-bottom: 6%;
			text-align: center;
		}
	}
	.footer-content p{
		color: #fff;
		@media all and (max-width: 699px) and (min-width: 300px) {
			margin-top: 5%;
			margin-bottom: 3%;
			text-align: center;
		}
	}
}