// Fonts 
$font-family-sans-serif: 'Roboto', Helvetica, Arial, sans-serif;
//Border radii
$border-radius-base:        2px;
$border-radius-large:       3px;
$border-radius-small:       1.5px;

// colors
$lakeview-gray: 		#9eb5be;
$lakeview-blue: 		#39baff;
$lakeview-green: 		#3cffcf;
$lakeview-dark-gray: 	#424242;
$lakeview-off-white:    #fbfbfb; //#fbfafa;
$lakeview-orange:       #ff7a39;
$lakeview-purple:       #6686B7;
$brand-priamry: 		$lakeview-blue;
$link-color: 			$lakeview-blue;
$section-border:        #b4b4b4;
$blockquote-border-color: $lakeview-blue;
$pagination-active-bg: $lakeview-blue;
$pagination-active-border: darken($lakeview-blue, 10%);

// NavBar 
$navbar-default-bg:    					#fff;
$navbar-default-toggle-border-color: 	#fff;
$navbar-default-toggle-hover-bg:		#f6f6f6;
$navbar-default-toggle-icon-bar-bg:     #9eb5bf;
$navbar-default-border:					none;

//Lakeviw Button 
$btn-lakeview-border-color: #3ca0fe;//#3cbbfe;
$btn-lakeview-bg-color: $lakeview-blue; //#71cff4;
$btn-font-weight: 300;

$border-shadow: #eee;
$border-shadow-hover: #dcdcdc;

$media-border-shadow: #ccc;
$media-border-shadow-hover: darken($media-border-shadow, 25%);