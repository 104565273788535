.series-heading {
	@include make-row();
	background: -webkit-linear-gradient(-60deg, $lakeview-blue, lighten($lakeview-blue, 90%));
	background: linear-gradient(120deg, $lakeview-blue, lighten($lakeview-blue, 90%));
	// background: -ms-linear-gradient(-60deg, $lakeview-blue, lighten($lakeview-blue, 90%));
	background-attachment: scroll;
    min-height: 540px;
    padding-top: 10%;
    padding-bottom: 10%;
    @media all and (max-width: 699px) and (min-width: 300px) {
		padding-top: 0;
	}
    .series-info {
		@include make-sm-column(10);
		@include make-sm-column-offset(1);
		@include make-xs-column(12);
		padding: 0;
		.series-img {
			@include make-sm-column(5);
			@include make-xs-column(12);
			float: right !important;
			padding: 0;
			img {
				@include center-block();
				border: 5px solid #fff;
				@media all and (max-width: 699px) and (min-width: 300px) {
					margin-bottom: 5%;
					border: none;
				}
			}
		}
		h1 {
			margin-top: 0;
			text-align: left;
			font-weight: 700;
			font-size: 48px;
			color: #fff;
			@media all and (max-width: 699px) and (min-width: 300px) {
				padding-left: 5%;
				padding-right: 5%;
				font-size: 36px;
			}
		}
		p {
			@include make-sm-column(7);
			color: $gray-darker;
			font-size: 1.25em;
			text-align: left;
			padding-right: 10%;
			padding-left: 0;
			@media all and (max-width: 699px) and (min-width: 300px) {
				padding-left: 5%;
				padding-right: 5%;
			}
			a {
				color: #fff;
			}
		}
	}
}

.sermons-list {
	@include make-row();
	ul {
		@include make-sm-column(8);
		@include make-sm-column-offset(2);
		@include make-xs-column(12);
		// border: 1px solid $gray-lighter;
		margin-bottom: 40px;
		margin-top: 20px;
		@media all and (max-width: 699px) and (min-width: 300px) {
			padding: 0;
			margin-bottom: 20px;
			margin-top: 20px;
		}
	}
	li {
		margin-bottom: 5px;
		list-style: none;
		width: 100%;
		min-height: 125px;
		background: #f8f8f8;
		display: table;
	}
	.list-style {
		// @include make-sm-column(2)
		background: $gray-lighter;
		color: $gray-light;
		font-weight: 700;
		font-size: 24px;
		width: 60px;
		height: 60px;
		display: table;
		text-align: center;
		float: left;
		@media all and (max-width: 699px) and (min-width: 300px) {
			display: none;
		}
		span {
			display: table-cell;
			vertical-align: middle;
			@media all and (max-width: 699px) and (min-width: 300px) {
				display: none;
			}
		}
	}
	.details {
		@include make-sm-column(10);
		// margin-top: 20px;
		display: table-cell;
		vertical-align: middle;
		margin-bottom: 5px;
		font-weight: 700;
		font-size: 1.15em;
		height: 100%;
		color: $gray-light;
		.top {
			margin-top: 20px;
			.light {
				color: lighten($gray-light, 20%);
			}
		}
		h3 {
			font-weight: 300;
			margin-top: 0;
			// margin-bottom: 15px;
		}
		p {
			font-weight: 300;
			letter-spacing: -.025em;
			@media all and (max-width: 699px) and (min-width: 300px) {
				padding-bottom: 20px;
			}
		}
	}
}