.article-wrapper {
	@include make-sm-column(3);
	@include make-xs-column(12);
	@include center-block();
	// margin-top: 5%;
	margin-bottom: 5%;
}
.article-link {
	width: 100%; 
	height: 100%;
	-moz-box-shadow:    0 0 8px 1px $border-shadow;
  	-webkit-box-shadow: 0 0 8px 1px $border-shadow;
  	box-shadow:         0 0 8px 1px $border-shadow;
  	padding: 0;
  	border-radius: 0 0  7px 7px;
}
.article-link:hover {
	-moz-box-shadow:    0 0 8px 1px $border-shadow-hover;
  	-webkit-box-shadow: 0 0 8px 1px $border-shadow-hover;
  	box-shadow:         0 0 8px 1px $border-shadow-hover;
}
.article-link .copy {
	padding: 0 15px 0 15px;
	height: 150px;
}
.article-link h3 {
	// @include text-overflow();
	// display: block;
	font-weight: 700;
	// height: 30px;
	color: $lakeview-dark-gray;
}
.article-link h3 a{
	color: $gray;
}
.article-link img {
	border-radius: 7px 7px 0 0;
}
.article-link .author {
	background: $lakeview-off-white;
	padding: 10px 0 10px 0;
	// color: $lakeview-gray;
	border-radius: 0 0  7px 7px;
	border-top: 1px solid #f1f1f1;
}
.article-link .author p {
	font-weight: 700;
	padding: 5px 15px 0 15px;
	display: inline-block;
}
/// Show Article styles
.article {
	@include make-sm-column(8);
	@include make-sm-column-offset(2);
	@include make-xs-column(12);
}
article h1 {
	margin-top: 5%;
	font-weight: 700;
	color: $lakeview-dark-gray;
	font-size: 55px;
	text-align: center;
	@media all and (max-width: 699px) and (min-width: 300px) {
		font-size: 36px;
	}
}
article #subtitle {
	text-align: center;
	font-weight: 100;
	color: $lakeview-dark-gray;
}
article #credits {
	font-size: 14px;
	color: $gray-light;
	text-align: center;
}
article #credits h4{
	font-weight: 700;
	text-align: center;
}
article h2 {
	font-weight: 700;
	color: #424242;
	font-size: 36px;
	text-align: left;
	@media all and (max-width: 699px) and (min-width: 300px) {
		font-size: 24px;
	}
}
article p {
	text-align: justify;
	font-size: 16px;
	line-height: 2em;
	@media all and (max-width: 699px) and (min-width: 300px) {
		font-size: 14px;
	}
}
article section {
	// margin-top: 25px;
}
article blockquote {
	font-weight: 700;
	// color: $lakeview-gray;
}
