@import "_custom-variables.scss";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "_identity.scss";
@import "_article.scss";
@import "_video.scss";
@import "_media.scss";
@import "_sermon-series.scss";
@import "_footer.scss";
.label-lakeview-green {
  @include label-variant($lakeview-purple);
}
.thin-text {
	font-weight: 100;
}
.navbar {
	margin-bottom: 0;
}
a.svg {
 	position: relative;
 	display: inline-block; 
}
a.svg:after {
  content: ""; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;
}

.btn-lakeview {
	width: 100%;
	background: $btn-lakeview-bg-color;
	border: 1px solid $btn-lakeview-border-color;
	color: #fff;
}
span.play-circle {
	background: url('/images/play-circle.svg') center center no-repeat;
	width: 20px;
	height: 20px;
	display: inline-block;
	position: relative;
	top: 3px;
	@media all and (max-width: 699px) and (min-width: 300px) {
		width: 15px;
		height: 15px;
	}
}

.btn-lakeview:hover {
	width: 100%;
	background: transparent;
	border: 1px solid $btn-lakeview-border-color;
	color: $lakeview-blue;
}

.home-page-heading {
	text-align: center;
	width: 100%;
	overflow: hidden;
	// background: #121214; //url('/images/asfalt.jpg') repeat;
	.church {
		background: url('/images/church.svg') center center no-repeat;
		height: 150px;
		margin-top: 20%;
		@inlude center-block();
		@media all and (max-width: 699px) and (min-width: 300px) {
			height: 100px;
			margin-top: 10%;
		}
	}
	.focus {
		background: url('/images/map.png') 50% 50% no-repeat;
		background-size: cover;
	}
	h1 {
		color: $gray-light;
		font-size: 72px;
		font-weight: 100;
		@media all and (max-width: 699px) and (min-width: 300px) {
			font-size: 36px;
		}
	}
	.heading-subtext {
		font-weight: 100;
		text-align: center;
		line-height: 1.5em;
		margin: 0;
		@media all and (max-width: 699px) and (min-width: 300px) {
			font-size: 18px;
		}
	}
}

.latest-article {
	@include make-row();
	.details {
		@include make-sm-column(6);
		@include make-sm-column-offset(3);
		padding: 5%;
		margin-top: 5%;
		margin-bottom: 5%;
		background: rgba(0, 0, 0, .5);
		border-radius: 7px;
		@media all and (max-width: 699px) and (min-width: 300px) {
			padding-top: 5%;
			padding-bottom: 5%;
		}
		h2 {
			font-size: 48px;
			text-align: center;
			color: $gray-lighter;
			text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4); 
			margin-bottom: 5%;
		}
		h3 {
			font-size: 24px;
			text-align: center;
			color: $gray-lighter;
			text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4); 
		}
	}
}

.page-heading {
	text-align: center;
	// background: #121214; //url('/images/asfalt.jpg') repeat;
	.helix {
		background: url('/images/helix.svg') center center no-repeat;
		height: 150px;
		margin-top: 20%;
		@inlude center-block();
		@media all and (max-width: 699px) and (min-width: 300px) {
			height: 80px;
			margin-top: 10%;
		}
	}
	.article {
		background: url('/images/article.svg') center center no-repeat;
		height: 150px;
		margin-top: 20%;
		@inlude center-block();
		@media all and (max-width: 699px) and (min-width: 300px) {
			height: 80px;
			margin-top: 10%;
			margin-bottom: 10%;
		}
	}
	.media {
		background: url('/images/media.svg') center center no-repeat;
		height: 150px;
		margin-top: 20%;
		@inlude center-block();
		@media all and (max-width: 699px) and (min-width: 300px) {
			height: 80px;
			margin-top: 10%;
		}
	}
	.podium {
		background: url('/images/podium.svg') center center no-repeat;
		height: 150px;
		margin-top: 20%;
		@inlude center-block();
		@media all and (max-width: 699px) and (min-width: 300px) {
			height: 120px;
			margin-top: 10%;
		}
	}
	.logo-icon {
		background: url('/images/logo-icon.svg') center center no-repeat;
		height: 150px;
		margin-top: 20%;
		@inlude center-block();
		@media all and (max-width: 699px) and (min-width: 300px) {
			height: 80px;
			margin-top: 10%;
		}
	}
	// .focus {
	// 	background: url('/images/map.png') no-repeat;
	// 	background-size: cover;
	// }
	h1 {
		color: $gray-light;
		font-size: 72px;
		font-weight: 100;
		@media all and (max-width: 699px) and (min-width: 300px) {
			font-size: 36px;
		}
	}
	.heading-subtext {
		font-weight: 100;
		color: $gray-light;
		text-align: center;
		line-height: 1.5em;
		margin: 0;
		@media all and (max-width: 699px) and (min-width: 300px) {
			font-size: 18px;
		}
	}
}

.title-divider {
	background: $lakeview-green;
	width: 100px; 
	height: 8px;
	margin: 15px 0 15px 0;
	@include center-block();
}

.title-divider-left {
	background: $gray-darker;
	width: 100px; 
	height: 8px;
	margin: 15px 0 15px 0;
	@media all and (max-width: 699px) and (min-width: 300px) {
		padding-left: 5%;
	}
}

.call-to-action {
	padding: 3% 0 3% 0;
	@media all and (max-width: 699px) and (min-width: 300px) {
		padding: 10% 0 15% 0;
	}
}

.full-width-pane {
	width: 100%;
	height: 100%;
	background: darken($lakeview-off-white, 1%);
	padding-top: 5%;
	padding-bottom: 5%;
	.heart {
		background: url('/images/heart.svg') center center no-repeat;
		height: 75px;
		@media all and (max-width: 699px) and (min-width: 300px) {
			margin-top: 10%;
			height: 50px;
		}
	}

	h2 {
		font-weight: 700;
		color: $gray-light;
		text-align: center;
	}

	h3 {
		font-weight: 700;
		color: $lakeview-blue;
		text-align: center;
	}

	h4 {
		font-weight: 100; 
		color: $gray;
		text-align: center;
		@media all and (max-width: 699px) and (min-width: 300px) {
			font-size: 16px;
		}
	}
}
.watch-sermons {
	text-align: center;
	height: 100%;
	padding-bottom: 5%;
	h1 {
		margin-top: 5%;
		font-weight: 100;
		color: $gray-light;
		@media all and (max-width: 699px) and (min-width: 300px) {
			margin-top: 14%;
		}
	}
	.btn-link {
		font-weight: 100;
		font-size: 1.5em;
	}
	button:hover {
		color: $gray-light;
		text-decoration: none;
	}
	.sermons-content {
		margin-top: 5%;
	}
	.right-side {
		h3 {
			font-weight: 100;	
			color: $gray-light;
		}
		h4 {
			color: $gray;
		}
		.divider{
			border-top: 1px dashed $section-border;
			margin-top: 5%;
			margin-bottom: 5%;
		}	
		.series {
			@media all and (max-width: 699px) and (min-width: 300px) {
				margin-top: 14%;
			}
		}
		.series .btn-link {
			font-size: 1.25em;
			span.play-circle {
				background: url('/images/play-circle.svg') center center no-repeat;
				width: 15px;
				height: 15px;
				display: inline-block;
				position: relative;
				top: 3px;
				@media all and (max-width: 699px) and (min-width: 300px) {
					width: 15px;
					height: 15px;
				}
			}
		}
	} // End Right Side
} // end Watch Sermons
.paginate {
	// @include make-sm-column(4);
	// @include make-sm-column-offset(4);
	.links {
		width: 100%;
		@include center-block();
	}
}
.truncate{
	display: inline-block;
  	max-width: 350px;
  	@include text-overflow();
}
