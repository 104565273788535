@import "../css/video-js.min.css";
#video{
	@media all and (max-width: 699px) and (min-width: 300px) {
		margin-top: 0;
		margin-bottom: 0;
	}
}
.video-js {
	padding-top: 56.25%;
	position: relative;
}
// .video-dimensions {
// 	width: 100% !important;
// }
.vjs-fullscreen {
	padding-top: 0px !important;
}
.video-dimensions {
    width: 100%;
    height: 100%;
}
.video {
	@include make-row();
	background: -webkit-linear-gradient(-60deg, $gray-darker, $gray);
	background: linear-gradient(120deg, $gray-darker, $gray);
	// background: -ms-linear-gradient(-60deg, $gray-darker, $gray);
	.video-container {
		@include make-xs-column(12);
		@include make-sm-column(8);
		@include make-sm-column-offset(2);
		@include make-md-column(8);
		@include make-md-column-offset(2);
		margin-top: 40px;
		margin-bottom: 40px;
		// width: 100%;
		@media all and (max-width: 699px) and (min-width: 300px) {
			padding: 0;
			margin: 0;
		}
		video {
			width: 100% !important;
			height: auto !important;
		}
	}
}
.video-info	{
	@include make-xs-column(12);
	@include make-sm-column(8);
	@include make-sm-column-offset(2);
	h1 {
		font-weight: 700;
		color: $lakeview-dark-gray;
		text-align: center;
		font-size: 60px;
		margin-top: 5%;
		@media all and (max-width: 699px) and (min-width: 300px) {
			font-size: 36px;
		}
	}
	h3 {
		font-weight: 100;
		color: $lakeview-blue;
		text-align: center;
		// margin-top: 5%;
		// margin-bottom: 5%;
		@media all and (max-width: 699px) and (min-width: 300px) {
			// font-size: 36px;
		}
	}
	h4 {
		font-weight: 300;
		color: $gray-light;
		text-align: center;
		// margin-top: 5%;
		@media all and (max-width: 699px) and (min-width: 300px) {
			// font-size: 36px;
		}
	}
	p {
		text-align: center;
		font-size: 1.5em;
		line-height: 2em;
		color: $gray-light;
		@media all and (max-width: 699px) and (min-width: 300px) {
			font-size: 1.15em;
		}
	}
}
///// Video-resolutoin plugin styles..
.vjs-resolution-button {
  color: #ccc;
  font-family: VideoJS;
}

.vjs-resolution-button .vjs-resolution-button-staticlabel:before {
  content: '\f110';
  font-size: 1.8em;
  line-height: 1.67;
}

.vjs-resolution-button .vjs-resolution-button-label {
    font-size: 1em;
    line-height: 3em;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    box-sizing: inherit;
		font-family: Arial, Helvetica, sans-serif;
}

.vjs-resolution-button ul.vjs-menu-content {
  width: 4em !important;
}

.vjs-resolution-button .vjs-menu {
  left: 0;
}

.vjs-resolution-button .vjs-menu li {
  text-transform: none;
	font-size: 1em;
	font-family: Arial, Helvetica, sans-serif;
}
